<template>
  <div
    class="dropdown-wrapper enext-input"
    :class="[
      rules && rules.length ? 'enext-input--validable' : '',
      errorMessage ? 'invalid' : '',
      $attrs?.class ? $attrs.class : ''
    ]"
  >
    <kendo-dropdownlist
      class="dropdown k-dropdown"
      v-bind="customAttrs"
      @filterchange="filterChange"
      @open="onOpen"
      @close="onClose"
      @change="onChange"
      :icon-class-name="iconClassName"
      :data-items="items"
      ref="dropdownlist"
    >
      <template
        v-for="(_, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </kendo-dropdownlist>
    <ErrorMessage
      :error-message="errorMessage"
      class="enext-input__errormessage"
      ref="errormessage"
    />
  </div>
</template>

<script>
import {DropDownList} from '@progress/kendo-vue-dropdowns'
import dropdownMixin from '~/mixins/dropdown'
import ErrorMessage from '~/components/input-components/ErrorMessage'

export default {
  mixins: [dropdownMixin],
  name: 'DropDown',
  components: {
    ErrorMessage,
    'kendo-dropdownlist': DropDownList
  },
  props: {
    skipClientFilter: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  },
  methods: {
    close() {
      this.$refs.dropdownlist.handleKeyDown(new KeyboardEvent('keypress', {'keyCode': 27}))
    },
    onChange(e) {
      // run custom function onClick when its set
      const val = e.value || e.target.value
      // eslint-disable-next-line eqeqeq
      if (val?.onClick && typeof val.onClick === 'function') {
        val.onClick()
      }
    }
  }
}
</script>
